<template>
  <header class="main_header container py-12 md:py-24 overflow-x-hidden sm:overflow-x-visible">
    <p class="tt sub md:text-[40px] text-center">The Vitalik-Sanctioned Era of Memecoins:</p>
    <h1 class="tt text-5xl md:text-7xl text-center mb-9">Introducing <br class="sm:hidden"> a VIT Coin</h1>
    
    <div class="grid grid-cols-1 md:grid-cols-[auto_200px] gap-12 items-center max-w-[900px] mx-auto">
      <article class="space-y-5 order-2 md:order-1">
        <p>In the dynamic realm of cryptocurrencies, Vitalik Buterin, the founder of Ethereum, continues to shape the future with his insightful perspectives. Recently, Buterin penned a thought-provoking piece on the significance of memecoins, published on his personal site</p>
        <a class="btn_yellow shdw text-[9px] md:text-sm" target="_blank" href="https://vitalik.eth.limo/general/2024/03/29/memecoins.html">https://vitalik.eth.limo/general/2024/03/29/memecoins.html</a>
        <p>which has ignited discussions across the crypto community.</p>
        <p class="flex items-center main_social space-x-4">
          <a href="" class="social_btn sx shdw"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg></a>
          <a href="" class="social_btn st shdw"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg></a>
          <a href="" class="social_btn sbt shdw text-black gap-2 inline-flex items-center tt">
            Buy token
            <!-- <img src="@/assets/lock.svg" class="w-4 h-4" alt=""> -->
            <span class="shdw py-1 px-2 bg-[#ABE7C1] tt rounded-lg inline-block" style="transform:rotate(3deg);">Soon</span>
          </a>
        </p>
      </article>
      <article class="order-1 md:order-2 flex items-center justify-start">
        <img src="/img/buterin.jpg" class="shdw rounded-full max-w-[200px] mx-auto md:max-w-[300px]" alt="">
        <span class="image_tag shdw">VITALIK</span>
      </article>
    </div>
  </header>

  <img src="@/assets/coins.png" class="max-w-full hidden md:block" alt="">
  <img src="@/assets/coins_mob.jpg" class="max-w-full md:hidden" alt="">

  <section class="slogan border-b border-t border-black">
  </section>

  <div class="px-4 md:px-0">
    <section class="container main_about shdw">
    <article>
      <h2>About Us</h2>
      <p>Inspired by Buterin's support for memecoins, we're introducing a memecoin <span class="shdw py-1 px-2 bg-[#ABE7C1] tt rounded-lg inline-block" style="transform: rotate(3deg);">VIT (VITALIK)</span> that celebrates his contributions to blockchain technology and his positive stance on memecoins. This coin aims to encapsulate the spirit of creativity and community that <span  class="shdw py-1 px-2 bg-[#A6CDF4] tt rounded-lg inline-block" style="transform: rotate(-2deg);">Buterin champions</span>.</p>
      <p>This memecoin is not just a token of appreciation for Buterin but a symbol of ongoing innovation within the crypto world. It represents the fun and transformative nature of cryptocurrencies, aiming to foster a community around these values.</p>
    </article>
    <article class="main_about_image">
      <img src="@/assets/about_but.png" class="flower"  alt="">
    </article>
  </section>
  </div>

  <section class="airdrop py-12 ">
    <div class="container grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 items-center">
      <article class="space-y-9">
        <h2>Airdrop</h2>
        <p>Yo, folks! Hold onto your hats 'cause we got an epic airdrop in the works! Get ready to catch them tokens rainin' down like <span class="shdw py-1 px-2 bg-[#F2C43B] tt rounded-lg inline-block" style="transform: rotate(3deg);">confetti at a party!</span> We're puttin' in the elbow grease to make this airdrop as lit as a bonfire on the beach. Don't miss out on this chance to bag some crypto goodies! Stay tuned for updates and get your wallets prepped for the ultimate crypto fiesta!</p>
        <p class="flex main_social space-x-4">
          <a href="" class="social_btn s1 shdw"><img class="" src="@/assets/lock.svg" alt=""></a>
          <a href="" class="social_btn s2 shdw"><img src="@/assets/lock.svg" alt=""></a>
          <a href="" class="social_btn s3 shdw"><img src="@/assets/lock.svg" alt=""></a>

        </p>
      </article>
      <article>
        <img src="@/assets/tv.png" alt="">
      </article>
    </div>
  </section>

  <section class="slogan2 mt-12">

  </section>

  <section class="connection">
      <div class="container">
        
        <div class="nft_lead shdw grid grid-cols-1 md:grid-cols-2 items-center">
          <article>
            <h2 class="md:text-5xl">NFT collection</h2>
          </article>
          <article>
            <p>We're tweaking <span class="shdw py-1 px-2 bg-[#A6CDF4] tt rounded-lg inline-block" style="transform: rotate(-2deg);">our NFTs</span> like DJs spinning at a party! Cooking up something special that'll light up your collection like a stack of fire travel pics. Our crypto beauty is coming soon, so keep your ears to the ground!</p>
          </article>
        </div>

        <div class="grid grid-cols-3 gap-6 mt-12">
          <article class="nft_item shdw">
            <img src="@/assets/nft1.png" alt="">
          </article>
          <article class="nft_item shdw">
            <img src="@/assets/nft2.png" alt="">
          </article>
          <article class="nft_item shdw">
            <img src="@/assets/nft3.png" alt="">
          </article>
        </div>
      </div>
  </section>

  <section class="tokenomika">
    <div class="container">
      <h2 class="text-white md:text-6xl relative z-10">Tokenomica</h2>
      <div class="tokenomica_grid items-center grid grid-cols-3">
        <article class="md:items-end">
          <p class="bg-[#F59E97] tt md:max-w-[150px]">Liquidity 25%</p>
          <p class="bg-[#A6CDF4] tt mt-12 md:max-w-[220px]">Staking and burn 35%</p>
        </article>
        <article>
          <img src="@/assets/vit.svg" alt="">
        </article>
        <article>
          <p class="bg-[#F4EB84] tt md:max-w-[250px]">Presale 20%
            <small>(75% Liquidity, 25% Marketing)</small>
          </p>
          <p class="bg-[#ED87CA] tt mt-12 md:max-w-[260px]">Airdrop and affiliates 20%</p>
        </article>
      </div>
    </div>
  </section>

  <section class="slogan2" style="background-color: #F3C53C;">

  </section>

  <div class="roadmap py-12 px-2">
    <div class="max-w-[900px] mx-auto mb-12 relative">
      <h2 class="tt text-3xl z-20">Roadmap</h2>
       <img src="@/assets/planets.svg" class="absolute w-[100%] -mt-[20px] md:-mt-[80px]" style="z-index: 2;">
    </div>
    <div class="roadmap_container bg-[#A6CDF4] px-4 shdw py-4 max-w-[900px] mx-auto rounded-lg relative z-20">
      <header class="grid grid-cols-5 md:grid-cols-9 gap-4 items-center justify-center">
        <article class="item shdw bg-[#ED87CA]">
          <p>Presale</p>
        </article>
        <article class="item shdw bg-[#C499E0]">
          <p>Airdrop</p>
        </article>
        <article class="item shdw bg-[#F4EB84]">
          <p>NFT</p>
        </article>
        <article class="item shdw bg-[#F59E97]">
          <p>Staking</p>
        </article>
        <article class="item shdw soon">
          <img src="@/assets/lock_white.svg" alt="">
          <p><small>Soon</small></p>
        </article>
        <article class="item shdw soon xs-hide">
          <img src="@/assets/lock_white.svg" alt="">
          <p><small>Soon</small></p>
        </article>
        <article class="item shdw soon xs-hide">
          <img src="@/assets/lock_white.svg" alt="">
          <p><small>Soon</small></p>
        </article>
        <article class="item shdw soon xs-hide">
          <img src="@/assets/lock_white.svg" alt="">
          <p><small>Soon</small></p>
        </article>
        <article class="item shdw soon xs-hide">
          <img src="@/assets/lock_white.svg" alt="">
          <p><small>Soon</small></p>
        </article>
      </header>
      <footer>
        <p class="percents tt text-[#1BA334] mt-6">{{ roadmap }}%</p>
        <div class="w-full h-[30px] rounded-lg shdw bg-[#ABE7C1]">
          <div class="roadmap_bar" style="max-width: 20%;"></div>
        </div>
      </footer>
    </div>
  </div>

  <footer class="py-12">
    <div class="container">
      <p class="text-center text-xs md:text-sm">© Copyright. All rights reserved by Symbios Games</p>
    </div>
  </footer>

</template>

<script>
// @ is an alias to /src
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

export default {
  data() {
    return {
      roadmap:10,
      properties: [
        {'id': 1, 'location': 'Lefkoşa', 'img': '/img/prop1.webp'},
        {'id': 2, 'location': 'Gazimağusa', 'img': '/img/prop2.webp'},
        {'id': 3, 'location': 'Güzelyurt', 'img': '/img/prop3.webp'},
        
      ]
    }
  }
}
</script>
